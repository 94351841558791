<template>
    <div>
        <div class="card card-custom card-stretch" v-if="user">
        <div class="card-body pt-4">
          <div class="d-flex align-items-center pt-5">
            <div class="symbol symbol-30 symbol-xxl-75 mr-5 align-self-start align-self-xxl-center">
              <Avatar :avatarData=user.avatar></Avatar>
            </div>
            <div>
                <span class="font-weight-bolder font-size-h5 text-dark-75" v-if="user.full_name">{{ user.full_name }}</span>
                <div class="text-muted" v-if="user.position">{{ user.position.name }}</div>
                <div class="mt-2">
                    <span class="badge badge-success font-weight-bold mr-2 py-2 my-1" v-if="user.active == 1">{{ $t("users.title.active") }}</span>
                    <span class="badge badge-danger font-weight-bold mr-2 py-2 my-1" v-else>{{ $t("users.title.unactive") }}</span>

                    <span class="font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1" v-if="user.confirmed == 1"><span class="fa fa-check text-success"></span> {{ $t("users.title.confirmed") }}</span>
                    <span class="font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1" v-else><span class="fa fa-times text-danger"></span> {{ $t("users.title.unconfirmed") }}</span>
                </div>
            </div>
          </div>
          <!--end::User-->
          <!--begin::Nav-->
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded mt-10">
            <div class="navi-item mb-2">
                <router-link :to="{ name: 'users_overview'}" class="navi-link py-4">
                    <span class="navi-icon mr-2">
                        <span class="svg-icon">
                            <inline-svg src="/media/svg/icons/Design/Layers.svg" />
                        </span>
                    </span>
                    <span class="navi-text font-size-lg">{{ $t("users.heading.overview") }}</span>
                </router-link>
            </div>
            <div class="navi-item mb-2">
                <router-link :to="{ name: 'users_edit'}" class="navi-link py-4">
                    <span class="navi-icon mr-2">
                    <span class="svg-icon">
                        <inline-svg src="/media/svg/icons/General/User.svg" />
                    </span>
                    </span>
                    <span class="navi-text font-size-lg">{{ $t("users.heading.edit") }}</span>
                </router-link>
            </div>
            <div class="navi-item mb-2">
                <router-link :to="{ name: 'users_password'}" class="navi-link py-4">
                    <span class="navi-icon mr-2">
                    <span class="svg-icon">
                        <inline-svg src="/media/svg/icons/General/Lock.svg" />
                    </span>
                    </span>
                    <span class="navi-text font-size-lg">{{ $t("users.heading.password") }}</span>
                </router-link>
            </div>
            <div class="navi-item mb-2">
                <router-link :to="{ name: 'users_roles'}" class="navi-link py-4">
                    <span class="navi-icon mr-2">
                    <span class="svg-icon">
                        <inline-svg src="/media/svg/icons/General/Shield-protected.svg" />
                    </span>
                    </span>
                    <span class="navi-text font-size-lg">{{ $t("users.heading.roles") }}</span>
                </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Avatar from "@/view/content/Avatar";
export default {
  name: "user_overview",
  components: {
    Avatar
  },
  props: {
    user: null,
  },
};
</script>